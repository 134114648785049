import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import getApiError from '@/utils/getApiError';
import { API_TIMEOUT, IS_DEV_ENV, API_BASE_PATH, DEV_API_HOST } from '@/constants/common';
import { ApiError } from '@/types/api';

const getBaseUrl = () => {
  if (IS_DEV_ENV) {
    return DEV_API_HOST;
  }

  if (!window) {
    return API_BASE_PATH;
  }

  return `${window.location.origin}/${API_BASE_PATH}`;
};

export const httpClient = axios.create({
  baseURL: getBaseUrl(),
  timeout: API_TIMEOUT,
  withCredentials: true,
  xsrfHeaderName: 'x-xsrf-token',
  xsrfCookieName: 'XSRF-TOKEN',
});

const onSuccess = <T>(response: AxiosResponse<T>) => response;

function onError(error: ApiError) {
  // TODO: remove
  // eslint-disable-next-line
  console.error('Axios Error', error);
  // eslint-disable-next-line
  debugger;
  const errorResponse = getApiError(error, error.config);
  return errorResponse && Promise.reject(errorResponse);
}

httpClient.interceptors?.response.use(onSuccess, onError);

const request = <T = unknown>(options: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return httpClient.request<T>(options).then((response: AxiosResponse<T>) => response);
};

export default request;
